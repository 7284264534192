<template>
  <CampaignManager type="CAMPAIGN_MANAGER_LIST" :func-get-opportunities="getOpportunityIntervalDate"></CampaignManager>
</template>
<script>
import CampaignManager from '@/views/private/modules/thirdParty/campaignManager/CampaignManager.vue';
import { getOpportunityIntervalDate } from '@/services/modules/ThirdParty/opportunity';

export default {
  name: 'CampaignManagerList',
  components: { CampaignManager },
  methods: {
    getOpportunityIntervalDate,
  },
};
</script>
