var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CampaignManager", {
    attrs: {
      type: "CAMPAIGN_MANAGER_LIST",
      "func-get-opportunities": _vm.getOpportunityIntervalDate,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }